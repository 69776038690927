<template>
  <div class="page-constellations">
    <!-- Tutorial -->
    <TutorialSlide nameTutorial="tutorial-constellation" />

    <!-- Navbar -->
    <Navbar />

    <!-- Sidebar -->
    <Sidebar />

    <!-- Main Constellations -->
    <section id="constellation" class="constellations">
      <main v-if="constellations.length" class="container position-relative">
        <div
          ref="constellationsSwiper"
          v-swiper:constellationsSwiper="swiperConstellationsOptions"
          @transitionEnd="constellationChanged"
          class="constellations-wrap"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              :key="`constellation-${i}`"
              v-for="(constellation, i) in constellations"
            >
              <div class="d-flex flex-column align-items-center h-100">
                <div class="img">
                  <img
                    :src="`${urlImg}${constellation.image_light}`"
                    :alt="constellation.name"
                    :light="`${urlImg}${constellation.image_light}`"
                    :dark="`${urlImg}${constellation.image_dark}`"
                  />
                </div>

                <div class="name text-center">
                  <h2 class="text-uppercase">{{ constellation.name }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrap-icons">
          <div class="icon-active">
            <svg
              width="88"
              height="88"
              viewBox="0 0 88 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="44"
                cy="44"
                r="43"
                stroke="#FF4646"
                stroke-width="2"
                stroke-dasharray="10 10"
              />
            </svg>
          </div>

          <div
            class="icons"
            ref="iconsSwiper"
            v-swiper:iconsSwiper="swiperIconsOptions"
            @transitionEnd="iconChanged"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(constellation, i) in constellations"
                :key="`icon-${i}`"
              >
                <div
                  class="icon"
                  :class="[
                    activeConstellation == i ? 'active' : '',
                    activeConstellation - 1 == i ? 'active-sibling-1' : '',
                    activeConstellation + 1 == i ? 'active-sibling-1' : '',
                  ]"
                >
                  <img
                    :src="`${urlImg}${constellation.icon_light}`"
                    :alt="constellation.name"
                    :light="`${urlImg}${constellation.icon_light}`"
                    :dark="`${urlImg}${constellation.icon_dark}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div v-else class="container container-skeleton">
        <div class="row justify-content-center align-items-center">
          <div
            class="
              col
              d-flex
              flex-column
              justify-content-center
              align-items-center
              h-100
            "
          >
            <b-skeleton animation="wave" width="60%" height="75px"></b-skeleton>

            <b-skeleton
              animation="wave"
              width="50%"
              height="50px"
              class="mt-3"
            ></b-skeleton>
          </div>
        </div>
      </div>

      <Parallax en="CONSTELLATIONS" id="RASI BINTANG" title="CONSTELLATIONS" />
    </section>

    <!-- Footer -->
    <Footer isSmall />

    <!-- Toggle Mobile -->
    <ToggleMobile />

    <!-- Help Mobile -->
    <HelpButton />
  </div>
</template>

<script>
// Library
import { mapState } from "vuex";
import { directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

// Layouts
import Navbar from "@/components/layouts/Navbar.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";
import Footer from "@/components/layouts/Footer.vue";

// Components
import Parallax from "@/components/components/Parallax.vue";
import ToggleMobile from "@/components/components/ToggleMobile.vue";
import TutorialSlide from "@/components/components/TutorialSlide";
import HelpButton from "@/components/components/HelpButton.vue";

// Services
import initParallax from "@/services/parallax/InitParallax.js";
import { getConstellations } from "@/services/admin/Constellations.js";
import { initMode } from "@/services/Mode.js";

export default {
  name: "Constellation",
  metaInfo: {
    title: "Constellation - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  components: {
    Navbar,
    Sidebar,
    Footer,
    Parallax,
    ToggleMobile,
    TutorialSlide,
    HelpButton,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapState("tutorial", {
      constellation: (state) => state.constellation,
    }),
  },
  data() {
    return {
      urlImg: process.env.VUE_APP_STORAGE_URL,
      swiperConstellationsOptions: {
        grabCursor: true,
        speed: 500,
        slidesPerView: 1,
        initialSlide: 0,
      },
      swiperIconsOptions: {
        grabCursor: true,
        speed: 500,
        centeredSlides: true,
        initialSlide: 0,
        slidesPerView: 3,

        breakpoints: {
          // Tablet
          768: {
            slidesPerView: 5,
          },
        },
      },
      constellations: [],
      activeConstellation: 0,
    };
  },
  mounted() {
    initParallax();
    this.getData();

    if (!this.constellation) {
      document.querySelector(".tutorial").classList.toggle("active");
    }
  },
  methods: {
    constellationChanged(swiper) {
      this.activeConstellation = swiper.realIndex;

      const iconsSwiper = this.$refs.iconsSwiper.swiper;
      iconsSwiper.slideTo(this.activeConstellation);

      this.removeName();
      this.setName();
    },
    iconChanged(swiper) {
      this.activeConstellation = swiper.realIndex;

      const constellationsSwiper = this.$refs.constellationsSwiper.swiper;
      constellationsSwiper.slideTo(this.activeConstellation);

      this.removeName();
      this.setName();
    },
    removeName() {
      const sliders = document.querySelectorAll(".swiper-slide .name");
      sliders.forEach((slider) => {
        slider.classList.remove("active");
      });
    },
    setName() {
      document
        .querySelector(".swiper-slide-active .name")
        .classList.add("active");
    },
    getData() {
      getConstellations().then((response) => {
        this.constellations = response.data.body.data.constellations;

        const middle = Math.round((this.constellations.length - 1) / 2);

        this.activeConstellation = middle;
        this.swiperConstellationsOptions.initialSlide = middle;
        this.swiperIconsOptions.initialSlide = middle;

        setTimeout(() => {
          initMode();
          this.setName();
        }, 100);
      });
    },
  },
};
</script>